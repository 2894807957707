// Webpack Imports
import * as bootstrap from 'bootstrap';


( function () {
	'use strict';

	// Focus input if Searchform is empty
	[].forEach.call( document.querySelectorAll( '.search-form' ), ( el ) => {
		el.addEventListener( 'submit', function ( e ) {
			var search = el.querySelector( 'input' );
			if ( search.value.length < 1 ) {
				event.preventDefault();
				search.focus();
			}
		} );
	} );

	// Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
	var popoverTriggerList = [].slice.call( document.querySelectorAll( '[data-bs-toggle="popover"]' ) );
	var popoverList = popoverTriggerList.map( function ( popoverTriggerEl ) {
		return new bootstrap.Popover( popoverTriggerEl, {
			trigger: 'focus',
		} );
	} );
} )();






$("body").on('wheel', function (e) {

        
    setTimeout(function() {

        var currentWidth = window.innerWidth;
        
        if (currentWidth > 768){
            
            $("#navbar").removeClass("active");
            $("#right").removeClass("active");
            $(".navbar-toggler").removeClass("active");
            $('body').removeClass('unscrollable');
        }

    }, 101);


});




$('.navbar-toggler').on('click',function(){
	$('#navbar,#right').toggleClass('active');
    $('body').toggleClass('unscrollable');
    $(this).toggleClass('active');
    $('#header .dropdown-toggle.active').removeClass("active");
});

$('#search__show,#search__close').on('click',function(){
    $('#search__fixed').toggleClass('active');
});


$(document).on ("click", "#lang__show", function () {
    $('#lang .menu').slideToggle();
    $(this).toggleClass('active');
});





$('#scroll-page.down').on('click',function(){
    $("html, body").animate({ scrollTop: $(document).height() }, 1000);
  });




  function checkDivLocation() {
    var $window = $(window);
    var window_bottom = $window.scrollTop() + $window.height();
  
    $('.fadeIn,.fadeInLeft,.fadeInRight').each(function(i) {
      var $that = $(this);
      var div_bottom = $that.position().top + ($that.outerHeight()/3);
  
      if (window_bottom > div_bottom) {
        $(this).addClass('visible');
      }
    });
  }
  checkDivLocation();
  
  $(window).on('scroll', function() {
    checkDivLocation();
  });

  checkDivLocation();


$(window).scroll(function(){

     if ($(window).scrollTop() > 300){
      $('#scroll-page').addClass('up').removeClass('down');
        $('#scroll-page.up').on('click',function(){
            $('html, body').animate({ scrollTop: 0 }, 400);
        });

    } else {
      $('#scroll-page').removeClass('up').addClass('down');
    }
  });


$('.dropdown-toggle').on('click',function(){
	$(this).siblings('.dropdown').fadeToggle();
    $(this).toggleClass('active');
    $(this).parent().siblings().children('a').removeClass('active');
});


$('.vh-popup__btn').on('click',function(){
    $(this).siblings('.vh-popup__modal').fadeIn();
});
$('.vh-popup__close').on('click',function(){
    $(this).closest('.vh-popup__modal').fadeOut();
});


import 'owl.carousel';

$(".product-block__slider.owl-carousel").owlCarousel({
    center: false,
    loop: true,
    nav:false,
    swap:false,
    margin:15,
    dots:false,
    responsive:{
        0:{
            items:1,
			swap:true,
			nav:false,
            dots:true,
            margin:0,
            loop:true
        },
		769:{
            items:2,
			swap:false,
			nav:false,
            dots:false,
            loop:true,
            margin:0
        },
        992:{
            items:3,
			swap:false,
			nav:false,
            dots:false,
            loop:true,
            margin:15
        }
    }
});



function navPosition(){

    $('.product-block__slider').each(function(){
        var maxHeight = -1;
        $(this).children().children().children().children().children('.product-block__slider__txt').each(function() {
          maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
        });
        $(this).children().children().children().children().children('.product-block__slider__txt').each(function() {
          $(this).height(maxHeight);
        });
    
        $(this).css('padding-top', maxHeight + 'px');
        $(this).css('margin-top','-' + maxHeight + 'px');
    });
    

}


$(document).ready(function () {
    navPosition();
});
$(window).resize(function(){
    navPosition();
});



$(".owl-carousel__cultures").owlCarousel({
    loop: true,
    nav:true,
    swap:false,
    dots:true,
    items:3,
    responsive:{
        0:{
            items:1,
            loop: true
        },
        993:{
            items:3,
            loop: true
        }
    }
    });

$('.circle__tabset li a').on('click',function(){
    event.preventDefault();
    $(this).parent('li').addClass('active');
    $(this).parent('li').siblings().removeClass('active');
    var circleId = $(this).attr('href');
    $(circleId).addClass('active');
    $(circleId).siblings().removeClass('active');
});

$('.main-accordion .main-accordion__opener').on('click',function(){
    event.preventDefault();
    $(this).toggleClass('active');
    $(this).siblings('.main-accordion__slide').slideToggle();
    $(this).parent('.main-accordion__item').siblings().children('.main-accordion__opener').removeClass('active');
    $(this).parent('.main-accordion__item').siblings().children('.main-accordion__slide').slideUp();
});

$('.map-section__result__accordion__opener.main-accordion__opener').on('click',function(){
    $(this).toggleClass('active');
    event.preventDefault();
    $(this).parent('h4').siblings('.main-accordion__slide').slideToggle();
});

$('.imagemap__map svg path').on('click',function(){
    $(this).addClass("active");
    $(this).siblings().removeClass("active");
    var mapId = $(this).attr('data-id');
    $('.imagemap__content-default').hide();
    $('#' + mapId).siblings().hide();
    $('#' + mapId).show();
});


$(function(){
    $(".imagemap__map svg path").keyup(function (e) {
      if (e.which == 13) {
        var mapId = $(this).attr('data-id');
        $('.imagemap__content-default').hide();
        $('#' + mapId).siblings().hide();
        $('#' + mapId).show();
      }
    });
  });

$('.region-map .region-group').on('click',function(){
    var regionId = $(this).attr('data-name');
    $('.map-section__default').hide();
    $('#region__' + regionId).siblings().hide();
    $('#region__' + regionId).show();
    $(this).addClass('active');
    $(this).siblings().removeClass('active');
    $('.big-pin').removeClass('active');
    $('.pin__' + regionId).addClass('active');
});



$('.filtering__btn').on('click',function(){
    var filterSlug = $(this).attr('data-slug');
    console.log(filterSlug);
    $(this).addClass('active');
    $(this).siblings().removeClass('active');
    $('.filtering__single__' + filterSlug).siblings().fadeOut();
    $('.filtering__single__' + filterSlug).fadeIn();
});

$('.footer__menu__title').on('click',function(){
    $(this).toggleClass('active');
    $(this).parents('.footer__menu__col').siblings().children('.footer__menu__title,.footer__menu').removeClass('active');
    $(this).siblings('.footer__menu').toggleClass('active');
});


$('.circle__accordion-opener').on('click',function(){
    event.preventDefault();
    $(this).toggleClass('active');
    $(this).parents('h2').siblings('.circle__accordion-slide').toggleClass('active');
    $(this).parents('.tab').siblings('.tab').children('h2').children('a').removeClass('active');
    $(this).parents('.tab').siblings('.tab').children('.circle__accordion-slide').removeClass('active');
});

$('.mailpoet_checkbox_label input').each(function(){
    $(this).after('<span></span>');
});


$('figure.wp-block-image img').each(function(){
    var imgSrc = $(this).attr('src');
    var img = $(this);
    $(this).wrap('<div class="figure__inner"></div>');
    $(this).after('<a data-fancybox class="photo-opener" href="' + imgSrc + '"><span></span><span></span><span></span><span></span></a>');
});

setTimeout(function() {

    Fancybox.bind('[data-fancybox]', {
        Image: {
          zoom: false,
        },
      });

}, 101);

$('.photo-opener__blockquote').on('click',function(){
    var modalId = $(this).attr('data-name');
    $('.' + modalId).fadeIn();
});


$('.photo-opener__close').on('click',function(){
    $(this).parent('.photo-opener__modal__inner').parent('.photo-opener__modal').fadeOut();
});


$(".photo-opener__modal__inner").on("click",function(e){

    if(e.target.tagName == "IMG") {
        e.stopPropagation();
    } else{
        $('.photo-opener__modal').hide();
    }
    });


$('#careerSlider input[name="acceptance-100"]').on('change',function(){

    if($(this).is(":checked")) {
        $('.btn__formslider__next').removeAttr('disabled')
    } else{
        $('.btn__formslider__next').attr('disabled',true);
    }

});



if ($("body").hasClass("blog")) {

    $('#btn__filtering').on('click',function(){
        $('#news__filtering').slideToggle();
        $(this).toggleClass('active');


    });

    const currentUrl= window.location;


             
    // if (location.href.match(/\/page\/(\d*)/)){
    //     var url = location.href;
    //     var url2 = url.replace(/\/page\/(\d*)/,'');
    //     window.location.href = url2;
    // };



    if (!(location.href.match('category_name'))){
        $('#news__filtering').hide();
    } else{
        $('#btn__filtering').addClass('active');
    }


    
    $('#news__filtering input').on('change',function(){
        
        $('#news__filtering').addClass('disabled');
        if ($(this).is(":checked")){
            var filter = $(this).attr('value') + '%2C';

        


    
            if (location.href.match('category_name')){
            var goToUrlFiltered = currentUrl + filter;

                var goToUpdated = goToUrlFiltered.replace(/\/page\/(\d*)/,'');
                window.location.href = goToUpdated;
            }
            else{
                var goToUrl = currentUrl  + '?category_name=' + filter;
                var goToUrlUpdated = goToUrl.replace(/\/page\/(\d*)/,'');
                window.location.href = goToUrlUpdated;
            }
        } else{
            const filter = $(this).attr('value') + '%2C';
            location.search = location.search.replace(filter,'');
        }
    
    });
    
    
    var catsUrl = window.location.href;
    var catsList = catsUrl.substring(catsUrl .indexOf('=') + 1);
    var catsArray = catsList.split("%2C");
    var newCatsArr =  catsArray .map(function(el) {
        return '#' + el;
    })
    
    $(newCatsArr).each(function (){
         var catsVal = $(this).val();
         $('.filtering__' + catsVal).prop('checked',true);
         
    });


    
    

}
//offer form branches

$('.add-next-species').on('click',function(){
    event.preventDefault();
    $(this).parents('.species__toggle').removeClass('active');
    $(this).parents().next('.species__single').addClass('active');
    $(this).parents().next('.species__single').next('.species__toggle').addClass('active');
});

$('.remove-species').on('click',function(){
    event.preventDefault();
    $(this).parents().prev('.species__single').children('span').children('input').val('');
    $(this).parents().prev('.species__single').removeClass('active');
    $(this).parents('.species__toggle').removeClass('active');
    $(this).parents().prev('.species__single').prev('.species__toggle').addClass('active');
});


$( ".form__phone-code").focusout(function() {
    var code = $(this).val();
    var empty = /^(?=\s*$)/
    var reg1 = /^\+[1-9]{1}/;
    var reg2 = /^\+[1-9]{2}/;
    var reg3 = /^\+[1-9]{3}/;
    if (((code.match(reg1)) || (code.match(reg2)) || (code.match(reg3)) || (code.match(empty))) && (code.length < 5)){
        $(this).parent().siblings('.form__phone-code__info').removeClass('active');
    }
    else{
        $(this).parent().siblings('.form__phone-code__info').addClass('active');

    }

});



$('#offer-checkbox input').on('change',function(){
    const checkedNum = $('#offer-checkbox input:checked').length;

        if (checkedNum == '2'){
            $('#offer-checkbox input:not(:checked)').parent('label').addClass('disabled');
        } else{
            $('#offer-checkbox input:not(:checked)').parent('label').removeClass('disabled');
        }
});


$('#offer-checkbox-bl input').on('change',function(){
    const checkedNum = $('#offer-checkbox-bl input:checked').length;

        if (checkedNum == '2'){
            $('#offer-checkbox-bl input:not(:checked)').parent('label').addClass('disabled');
        } else{
            $('#offer-checkbox-bl input:not(:checked)').parent('label').removeClass('disabled');
        }
});







  $("#offer-checkbox .wpcf7-list-item-label").html(function(index, html) {
    return html.replace('®', '<sup>®</sup>');
  });

  
  $("#offer-checkbox-bl .wpcf7-list-item-label").html(function(index, html) {
    return html.replace('®', '<sup>®</sup>');
  });

$('input[name="lv-company-number"],input[name="lv-phone-number"],input[name="lv-phone-number-mobile"]').on('keyup',function(){
    this.value = this.value.replace(/[^0-9\.]/g,'');
});



$('.row__activities select').on('change',function(){
    const selected1 = $('#activity-1').val();
    const selected2 = $('#activity-2').val();
    const selected3 = $('#activity-3').val();
    console.log(selected3);

   $('.row__activities select option').show();
    
    setTimeout(function() {

        if ((!(selected1 == '') && !(selected2 == '') && !(selected3 == ''))){
            $('.row__activities .wpcf7-form-control-wrap option[value="' + selected1 +'"]').hide();
            $('.row__activities .wpcf7-form-control-wrap option[value="' + selected2 +'"]').hide();
            $('.row__activities .wpcf7-form-control-wrap option[value="' + selected3 +'"]').hide();
        }

        
        if ((!(selected1 == '') && (selected2 == '') && (selected3 == ''))){
            $('.row__activities .wpcf7-form-control-wrap option[value="' + selected1 +'"]').hide();
        }

        if (((selected1 == '') && !(selected2 == '') && (selected3 == ''))){
            $('.row__activities .wpcf7-form-control-wrap option[value="' + selected2 +'"]').hide();
        }

        if (((selected1 == '') && (selected2 == '') && !(selected3 == ''))){
            $('.row__activities .wpcf7-form-control-wrap option[value="' + selected3 +'"]').hide();
        }
    

        if ((!(selected1 == '') && !(selected2 == '') && (selected3 == ''))){
            $('.row__activities .wpcf7-form-control-wrap option[value="' + selected1 +'"]').hide();
            $('.row__activities .wpcf7-form-control-wrap option[value="' + selected2 +'"]').hide();
        }

        if ((!(selected1 == '') && (selected2 == '') && !(selected3 == ''))){
            $('.row__activities .wpcf7-form-control-wrap option[value="' + selected1 +'"]').hide();
            $('.row__activities .wpcf7-form-control-wrap option[value="' + selected3 +'"]').hide();
        }

        
        if (((selected1 == '') && !(selected2 == '') && !(selected3 == ''))){
            $('.row__activities .wpcf7-form-control-wrap option[value="' + selected2 +'"]').hide();
            $('.row__activities .wpcf7-form-control-wrap option[value="' + selected3 +'"]').hide();
        }


    }, 100); 


});
